import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { authConfig } from "../../../api-config/authConfig"

const initialState = {
    changePwdLoading : false,
    changePasswordResponse : [],
    error : ""
}
// change_status_user_staff_admin/{id}
// user_staff_update_by_admin/{id}
const staffChangePassword = createAsyncThunk("api/staffChangePassword", async(formData) => {
    return await authConfig.post("/changes_password_Admin_staff", formData).then((response) => {
        return response.data
    }).catch((e) => {
        console.log(e, "Error")
    })
})

const ChangeStaffPasswordSlice = createSlice({
    name : "ChangeStaffPasswordSlice",
    initialState,
    extraReducers: (builder) => {
        builder.
        addCase(staffChangePassword.pending, (state) => {
            state.changePwdLoading = true
        })
        .addCase(staffChangePassword.fulfilled, (state, {payload}) => {
            state.editResponse = payload
            state.changePasswordResponse = false
        })
        .addCase(staffChangePassword.rejected, (state) => {
            state.changePwdLoading = false
            state.error = "Something went wrong!"
        })
    }
})

export {staffChangePassword}

export default ChangeStaffPasswordSlice.reducer