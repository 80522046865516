import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { authConfig } from "../../../api-config/authConfig"
import Headers from "../../../api-config/Headers"

const initialState = {
    editLoading : false,
    editResponse : [],
    error : ""
}
// change_status_user_staff_admin/{id}
// user_staff_update_by_admin/{id}
const editSchoolStaff = createAsyncThunk("api/EditSchoolStaff", async(formData) => {
    return await authConfig.post("/user_staff_update_by_school", formData, {headers: Headers()}).then((response) => {
        return response.data
    }).catch((e) => {
        console.log(e, "Error")
    })
})

const EditSchoolStaffSlice = createSlice({
    name : "EditSchoolStaffSlice",
    initialState,
    extraReducers: (builder) => {
        builder.
        addCase(editSchoolStaff.pending, (state) => {
            state.editLoading = true
        })
        .addCase(editSchoolStaff.fulfilled, (state, {payload}) => {
            state.editResponse = payload
            state.editLoading = false
        })
        .addCase(editSchoolStaff.rejected, (state) => {
            state.editLoading = false
            state.error = "Something went wrong!"
        })
    }
})

export {editSchoolStaff}

export default EditSchoolStaffSlice.reducer