import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { authConfig } from "../../../api-config/authConfig"

const initialState = {
 loadEditResponse : false,
 editResponse : [],
 error : ""
}

const editSuperAdminRoles = createAsyncThunk("api/editSuperAdminRoles", async(formData) => {
    return await authConfig.post(`/role_update_by_admin/${formData.id}`, formData).then((response) => {
        return response.data
    }).catch((e) => {
        console.log(e.error)
    })
})

const EditSuperAdminRolesSlice = createSlice({
    name : "EditSuperAdminRolesSlice",
    initialState,
    extraReducers : (builder) => {
        builder.
        addCase(editSuperAdminRoles.pending, (state) => {
            state.loadEditResponse = true
        }).
        addCase(editSuperAdminRoles.fulfilled, (state, {payload}) => {
            state.editResponse = payload
            state.loadEditResponse = false
        }).
        addCase(editSuperAdminRoles.rejected, (state) => {
            state.error = "Something went wrong!"
        })
    }
})

export {editSuperAdminRoles}
export default EditSuperAdminRolesSlice.reducer