import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { authConfig } from "../../../api-config/authConfig"

const initialState = {
    createLoading : false,
    createResponse : [],
    error : ""
}

const createSuperAdminStaff = createAsyncThunk("api/createSuperAdminStaff", async(formData) => {
    return await authConfig.post("/user_staff_store_by_admin", formData).then((response) => {
        return response.data
    }).catch((e) => {
        console.log(e.errors, "Error")
    })
})

const CreateSuperAdminStaffSlice = createSlice({
    name : "CreateSuperAdminStaffSlice",
    initialState,
    extraReducers: (builder) => {
        builder.
        addCase(createSuperAdminStaff.pending, (state) => {
            state.createLoading = true
        })
        .addCase(createSuperAdminStaff.fulfilled, (state, {payload}) => {
            state.createResponse = payload
            state.createLoading = false
        })
        .addCase(createSuperAdminStaff.rejected, (state, {error}) => {
            state.createLoading = false
            state.error = "Something went wrong!"
        })
    }
})

export {createSuperAdminStaff}

export default CreateSuperAdminStaffSlice.reducer