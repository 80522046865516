import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { authConfig } from "../../../api-config/authConfig"
import Headers from "../../../api-config/Headers"

const initialState = {
    removeLoading : false,
    removeResponse : [],
    error : ""
}

const removeSchoolStaff = createAsyncThunk("api/removeSchoolStaff", async(formData) => {
    return await authConfig.post('/destroy_staff_school', formData, {headers: Headers()}).then((response) => {
        return response.data
    }).catch((e) => {
        console.log(e, "Error")
    })
})

const RemoveSchoolStaffSlice = createSlice({
    name : "RemoveSchoolStaffSlice",
    initialState,
    extraReducers: (builder) => {
        builder.
        addCase(removeSchoolStaff.pending, (state) => {
            state.removeLoading = true
        })
        .addCase(removeSchoolStaff.fulfilled, (state, {payload}) => {
            state.removeResponse = payload
            state.removeLoading = false
        })
        .addCase(removeSchoolStaff.rejected, (state) => {
            state.removeLoading = false
            state.error = "Something went wrong!"
        })
    }
})

export {removeSchoolStaff}

export default RemoveSchoolStaffSlice.reducer