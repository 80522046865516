import axios from "axios"
import Headers from "./Headers"

//const siteUrl = "https://rukhsaty.com/backend"
const siteUrl = "https://bluegrasskings.com/backend"

// Local url amaljith

//const siteUrl = "http://192.168.25.113:8000/SVN_DSMS"
//const siteUrl = "http://192.168.25.193/DSMS_API"
//const StudentURL = siteUrl.concat("/backend/api/auth/student_registration_backend")
const StudentURL = siteUrl.concat("/api/student_registration_backend")

const noAuthConfig = axios.create({
  baseURL: siteUrl.concat("/api/")
  //baseURL: siteUrl.concat("/backend/api/auth/")      
})
const authConfig = axios.create({
  baseURL: siteUrl.concat("/api/auth/"),
  headers: Headers()
})
export { authConfig, noAuthConfig, StudentURL }
// axiosAPI.interceptors.response.use((response) => {
//   const { message } = response.data
//   if (
//     (message === "Unauthorised." ||
//       message === "Invalid Session." ||
//       message === "Unauthorised Authentication.") &&
//     !localStorage.getItem("sessionTimeoutNotified")
//   ) {
//     localStorage.setItem("sessionTimeoutNotified", true)
//     localStorage.removeItem("language")
//     localStorage.removeItem("token")
//     localStorage.removeItem("employerToken")
//     localStorage.removeItem("uid")
//     window.location.href = "/landingpage"
//   } else {
//     return response;
//   }
// });
//
//