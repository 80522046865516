import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { authConfig } from "../../../api-config/authConfig"

const initialState = {
    privilegeLoading :false,
    superAdminPrivileges:[],
    privilageStatus:[],
    error:''
}

const GetSuperAdminPrivilege = createAsyncThunk("api/getAdminPrivilege", async(formData) => {
   // return await authConfig.post("/get_privilege_By_module_admin", formData).then((response) => {
    return await authConfig.post("/get_privilege_for_admin_role", formData).then((response) => {
        return response.data
    }).catch((e) => {
        console.log("error", e)
    })
})

const GetSuperAdminPrivilegeSlice = createSlice({
    name:"GetSuperAdminPrivilegeSlice",
    initialState,
    extraReducers : (builder) => {
        builder.
        addCase(GetSuperAdminPrivilege.pending, (state) => {
            state.privilegeLoading = true
        })
        .addCase(GetSuperAdminPrivilege.fulfilled, (state, {payload}) => {
            state.superAdminPrivileges = payload
           // state.privilageStatus = payload.data.result.filter((data) => data.status).map((data) => data.privilege_id.toString())
           state.privilageStatus = payload.data.result
          //state.privilageStatus = payload.data.result.filter((data) => data.status).map((data) => data.rpd_privileges.toString())
            state.privilegeLoading = false
        })
        .addCase(GetSuperAdminPrivilege.rejected, (state) => {
            state.error = "Something went wrong"
        })
    }
})

export {GetSuperAdminPrivilege}
export default GetSuperAdminPrivilegeSlice.reducer