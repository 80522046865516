import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { authConfig } from "../../../api-config/authConfig"

const initialState = {
    reviewLoading :false,
    reviews:[],
    error:''
}
// https://bluegrasskings.com/backend/api/auth/feedback_rating_list

const getReview = createAsyncThunk("api/getReview", async(formData) => {
    return await authConfig.post("/feedback_rating_list", formData).then((response) => {
        return response.data
    }).catch((e) => {
        console.log("error", e)
    })
})

const ReviewSlice = createSlice({
    name:"ReviewSlice",
    initialState,
    extraReducers : (builder) => {
        builder.
        addCase(getReview.pending, (state) => {
            state.reviewLoading = true
        })
        .addCase(getReview.fulfilled, (state, {payload}) => {
            state.reviews = payload
            state.reviewLoading = false
        })
        .addCase(getReview.rejected, (state) => {
            state.error = "Something went wrong"
        })
    }
})

export {getReview}
export default ReviewSlice.reducer