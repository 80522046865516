import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { authConfig } from "../../../api-config/authConfig"
import Headers from "../../../api-config/Headers"

const initialState = {
    privilegeLoading: false,
    privileges: [],
    privilageStatus: [],
    error: ''
}

const getPrivilege = createAsyncThunk("api/getPrivilege", async (formData) => {
   // return await authConfig.post("/get_privilege_By_module", formData, { headers: Headers() }).then((response) => {
    return await authConfig.post("/get_privilege_for_school_admin_role", formData, { headers: Headers() }).then((response) => {
        return response.data
    }).catch((e) => {
        console.log("error", e)
    })
})

const PrivilegeSlice = createSlice({
    name: "PrivilegeSlice",
    initialState,
    extraReducers: (builder) => {
        builder.
            addCase(getPrivilege.pending, (state) => {
                state.privilegeLoading = true
            })
            .addCase(getPrivilege.fulfilled, (state, { payload }) => {
                state.privileges = payload
               // state.privilageStatus = payload.data.result.filter((data) => data.status).map((data) => data.privilege_id.toString())
               state.privilageStatus = payload.data.result
                state.privilegeLoading = false
            })
            .addCase(getPrivilege.rejected, (state) => {
                state.error = "Something went wrong"
            })
    }
})

export { getPrivilege }
export default PrivilegeSlice.reducer