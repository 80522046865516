import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { authConfig } from "../../../api-config/authConfig"

const initialState = {
    loading : false,
    getLoading : false,
    getStaffById : [],
    superAdminStaffs : [],
    error:""
}

const getSuperAdminStaff = createAsyncThunk("api/getSuperAdminStaff", async() => {
    return await authConfig.get("/list_user_staff_admin").then((response) => {
        return response.data
    }).catch((e) => {
        console.log("error", e)
    })
})

const getSuperAdminStaffById = createAsyncThunk("api/getSuperAdminStaffById", async(formData) => {
    return await authConfig.post('/deatils_view_admin_users', formData).then((response) => {
        return response.data
    }).catch((e) => {
        console.log(e.error)
    })
})

const getSuperAdminStaffSlice = createSlice({
    name :"getSuperAdminStaffSlice",
    initialState,
    extraReducers:(builder) => {
        builder.
        addCase(getSuperAdminStaff.pending, (state) => {
            state.loading = true
        })
        .addCase(getSuperAdminStaff.fulfilled, (state, {payload}) => {
            state.superAdminStaffs = payload
            state.loading = false
        })
        .addCase(getSuperAdminStaff.rejected, (state) => {
            state.error = "Something went wrong!"
            state.loading = false
        }).
        addCase(getSuperAdminStaffById.pending, (state) => {
            state.getLoading = true
        })
        .addCase(getSuperAdminStaffById.fulfilled, (state, {payload}) => {
            state.getStaffById = payload
            state.getLoading = false
        })
        .addCase(getSuperAdminStaffById.rejected, (state) => {
            state.error = "Something went wrong!"
            state.getLoading = false
        })
    }
})

// const PermissionSlice = createSlice({
//     name:"PermissionSlice",
//     initialState,
//     extraReducers: (builder) => {
//         builder.
//         addCase(getRoles.pending, (state) => {
//             state.loading = true
//         })
//         .addCase(getRoles.fulfilled, (state, {payload}) => {
//             state.roles = payload
//             state.loading = false
//         })
//         .addCase(getRoles.rejected, (state) => {
//             state.error = "Something went wrong"
//             state.loading = false
//         })
//     }
// })

export {getSuperAdminStaff, getSuperAdminStaffById}
export default getSuperAdminStaffSlice.reducer