// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import configCompoReducer from './configCompo/index'
import chat from '@src/views/apps/chat/store/reducer'
import todo from '@src/views/apps/todo/store/reducer'
import users from '@src/views/apps/user/store/reducer'
import email from '@src/views/apps/email/store/reducer'
import invoice from '@src/views/apps/invoice/store/reducer'
import calendar from '@src/views/apps/calendar/store/reducer'
import ecommerce from '@src/views/apps/ecommerce/store/reducer'
import dataTables from '@src/views/tables/data-tables/store/reducer'
import permissionSliceReducer from '../../views/pages/permissions/permissionSlice'
import moduleSliceReducer from '../../views/pages/permissions/moduleSlice'
import GetPrivilegeSliceReducer from '../../views/pages/permissions/GetPrivilegeSlice'
import storePrivilegeSliceReducer from '../../views/pages/permissions/SavePrivileges'
import reviewSliceReducer from '../../views/pages/review/reviewSlice'
import SuperAdminRoleSliceReducer from '../../views/pages/Permission-superadmin/SuperAdminRoleSlice'
import SuperAdminModuleSliceReducer from '../../views/pages/Permission-superadmin/SuperAdminModuleSlice'
import GetSuperAdminPrivilegeSliceReducer from '../../views/pages/Permission-superadmin/GetSuperAdminPrivilegeSlice'
import SaveSuperAdminPrivilegeSliceReducer from '../../views/pages/Permission-superadmin/SaveSuperAdminPrivilegeSlice'
import getSchoolStaffSliceReducer from '../../views/pages/staff_school/getSchoolStaffSlice'
import updateSchoolStaffSliceReducer from '../../views/pages/staff_school/updateSchoolStaffSlice'
import RemoveSchoolStaffSliceReducer from '../../views/pages/staff_school/RemoveSchoolStaffSlice'
// import CreateSchoolStaffSliceReducer from '../../views/pages/staff_dummy/CreateSchoolStaffSlice'
import getSuperAdminStaffSliceReducer from '../../views/pages/staff_admin/getSuperAdminStaffSlice'
import CreateSuperAdminStaffSliceReducer from '../../views/pages/staff_admin/CreateSuperAdminStaffSlice'
import RemoveSuperAdminStaffSliceReducer from '../../views/pages/staff_admin/RemoveSuperAdminStaffSlice'
import updateSuperAdminStaffSliceReducer from '../../views/pages/staff_admin/updateSuperAdminStaffSlice'
import getSuperAdminRolesSliceReducer from '../../views/pages/superadmin-role/getSuperAdminRolesSlice'
import RemoveSuperAdminRolesSliceReducer from '../../views/pages/superadmin-role/removeAdminRolesSlice'
import CreateSuperAdminRoleSliceReducer from '../../views/pages/superadmin-role/CreateSuperAdminRoleSlice'
import EditSuperAdminRolesSliceReducer from '../../views/pages/superadmin-role/editSuperAdminRoleSlice'
import genderAndCountrySliceReducer from '../../views/pages/staff_admin/genderAndCountrySlice'
import staffDesignationSliceReducer from '../../views/pages/staff_admin/staffDesignationSlice'
import EditSuperAdminStaffSliceReducer from '../../views/pages/staff_admin/editSuperAdminStaff'
import ChangeStaffPasswordSliceReducer from '../../views/pages/staff_admin/staffChangePasswordSlice'
import StatusChangeSuperAdminRoleSliceReducer from '../../views/pages/superadmin-role/statusChangeAdminRoles'
import createSchoolStaffSliceReducer from '../../views/pages/staff_school/CreateSchoolStaffSlice'
import ChangeSchoolStaffPasswordSliceReducer from '../../views/pages/staff_school/staffChangePasswordSlice'
import EditSchoolStaffSliceReducer from '../../views/pages/staff_school/editSchoolStaffSlice'
import SchoolStaffRolesSliceReducer from '../../views/pages/staff_school/schoolStaffDesignationSlice'

const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  configCompoReducer,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  ROLES : permissionSliceReducer,
  MODULES : moduleSliceReducer,
  PRIVILEGES : GetPrivilegeSliceReducer,
  SAVE_PRIVILEGES : storePrivilegeSliceReducer,
  REVIEWS : reviewSliceReducer,
  SUPER_ADMIN_ROLES : SuperAdminRoleSliceReducer,
  SUPER_ADMIN_MODULES : SuperAdminModuleSliceReducer,
  SUPER_ADMIN_PRIVILEGES : GetSuperAdminPrivilegeSliceReducer,
  SAVE_SUPER_ADMIN_PRIVILEGES : SaveSuperAdminPrivilegeSliceReducer,
 SCHOOL_STAFF_LIST : getSchoolStaffSliceReducer,
  SCHOOL_STAFF_UPDATE_RESPONSE : updateSchoolStaffSliceReducer,
  REMOVE_SCHOOL_STAFF_RESPONSE : RemoveSchoolStaffSliceReducer,
  CREATE_SCHOOL_STAFF : createSchoolStaffSliceReducer,
  EDIT_SCHOOL_STAFF : EditSchoolStaffSliceReducer,
  GET_SUPER_ADMIN_STAFF : getSuperAdminStaffSliceReducer,
  CREATE_SUPER_ADMIN_STAFF : CreateSuperAdminStaffSliceReducer,
  REMOVE_SUPER_ADMIN_STAFF : RemoveSuperAdminStaffSliceReducer,
  UPDATE_SUPER_ADMIN_STAFF : updateSuperAdminStaffSliceReducer,
  GET_SUPER_ADMIN_ROLES : getSuperAdminRolesSliceReducer,
  REMOVE_SUPER_ADMIN_ROLE : RemoveSuperAdminRolesSliceReducer,
  CREATE_SUPER_ADMIN_ROLES : CreateSuperAdminRoleSliceReducer,
  SUPER_ADMIN_ROLE_EDIT : EditSuperAdminRolesSliceReducer,
  GENDER_AND_NATIONALITY : genderAndCountrySliceReducer,
  GET_STAFF_DESIGNATION : staffDesignationSliceReducer,
  EDIT_SUPER_ADMIN_STAFF : EditSuperAdminStaffSliceReducer,
  CHANGE_PASSWORD_ADMIN_STAFF : ChangeStaffPasswordSliceReducer,
  SUPER_ADMIN_ROLE_STATUS_CHANGE : StatusChangeSuperAdminRoleSliceReducer,
  SCHOOL_STAFF_CHANGE_PASSWORD : ChangeSchoolStaffPasswordSliceReducer,
  GET_SCHOOL_STAFF_ROLES : SchoolStaffRolesSliceReducer
})

export default rootReducer
