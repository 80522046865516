import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { authConfig } from "../../../api-config/authConfig"
import Headers from "../../../api-config/Headers"

const initialState = {
    updateLoading : false,
    updateResponse : [],
    error : ""
}

const updateSchoolStaff = createAsyncThunk("api/updateSchoolStaff", async(formData) => {
    return await authConfig.post('/change_status_staff_school', formData, {headers: Headers()}).then((response) => {
        return response.data
    }).catch((e) => {
        console.log(e, "Error")
    })
})

const UpdateSchoolStaffSlice = createSlice({
    name : "UpdateSchoolStaffSlice",
    initialState,
    extraReducers: (builder) => {
        builder.
        addCase(updateSchoolStaff.pending, (state) => {
            state.updateLoading = true
        })
        .addCase(updateSchoolStaff.fulfilled, (state, {payload}) => {
            state.updateResponse = payload
            state.updateLoading = false
        })
        .addCase(updateSchoolStaff.rejected, (state) => {
            state.updateLoading = false
            state.error = "Something went wrong!"
        })
    }
})

export {updateSchoolStaff}

export default UpdateSchoolStaffSlice.reducer