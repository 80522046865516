import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { authConfig } from "../../../api-config/authConfig"
import Headers from "../../../api-config/Headers"

const initialState = {
  loading: false,
  schoolStaffs: [],
  getStaffById: [],
  error: ""
}

const getSchoolStaff = createAsyncThunk(
  "api/getSchoolStaff",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await authConfig.post("/list_usersatff_school", formData, {headers: Headers()})
      return response.data
    } catch (e) {
      return rejectWithValue("Something went wrong!")
    }
  }
)

const getSchoolStaffById = createAsyncThunk(
  "api/getSchoolStaffById",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await authConfig.post("/deatils_view_school_staff", formData, {headers: Headers()})
      return response.data
    } catch (e) {
      return rejectWithValue("Something went wrong!")
    }
  }
)

const getSchoolStaffSlice = createSlice({
  name: "SchoolStaffSlice",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getSchoolStaff.pending, (state) => {
        state.loading = true
      })
      .addCase(getSchoolStaff.fulfilled, (state, { payload }) => {
        state.schoolStaffs = payload
        state.loading = false
      })
      .addCase(getSchoolStaff.rejected, (state, action) => {
        state.error = action.payload ? action.payload : "Something went wrong!"
        state.loading = false
      })
      .addCase(getSchoolStaffById.pending, (state) => {
        state.loading = true
      })
      .addCase(getSchoolStaffById.fulfilled, (state, { payload }) => {
        state.getStaffById = payload
        state.loading = false
      })
      .addCase(getSchoolStaffById.rejected, (state, action) => {
        state.error = action.payload ? action.payload : "Something went wrong!"
        state.loading = false
      })
  }
})

export { getSchoolStaff, getSchoolStaffById }
export default getSchoolStaffSlice.reducer
