import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { authConfig } from "../../../api-config/authConfig"
import Headers from "../../../api-config/Headers"
const initialState = {
    moduleLoading: false,
    modules: [],
    error: ''
}

const getModules = createAsyncThunk("api/getModules", async () => {
    return await authConfig.get("/get_list_modules", {
        headers: Headers()
    }).then((response) => {
        return response.data
    }).catch((e) => {
        console.log("error", e)
    })
})

const ModuleSlice = createSlice({
    name: "ModuleSlice",
    initialState,
    extraReducers: (builder) => {
        builder.
            addCase(getModules.pending, (state) => {
                state.moduleLoading = true
            })
            .addCase(getModules.fulfilled, (state, { payload }) => {
                state.modules = payload
                state.moduleLoading = false
            })
            .addCase(getModules.rejected, (state) => {
                state.error = "Something went wrong"
            })
    }
})

export { getModules }
export default ModuleSlice.reducer