import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { authConfig } from "../../../api-config/authConfig"

const initialState = {
 loadCreateResponse : false,
 createResponse : [],
 error : ""
}

const createSuperAdminRoles = createAsyncThunk("api/removeSuperAdminRoles", async(formData) => {
    return await authConfig.post("/role_store_by_admin", formData).then((response) => {
        return response.data
    }).catch((e) => {
        console.log(e.error)
    })
})

const CreateSuperAdminRolesSlice = createSlice({
    name : "CreateSuperAdminRolesSlice",
    initialState,
    extraReducers : (builder) => {
        builder.
        addCase(createSuperAdminRoles.pending, (state) => {
            state.loadCreateResponse = true
        }).
        addCase(createSuperAdminRoles.fulfilled, (state, {payload}) => {
            state.createResponse = payload
            state.loadCreateResponse = false
        }).
        addCase(createSuperAdminRoles.rejected, (state) => {
            state.error = "Something went wrong!"
        })
    }
})

export {createSuperAdminRoles}
export default CreateSuperAdminRolesSlice.reducer