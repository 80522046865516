import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { authConfig } from "../../../api-config/authConfig"
import Headers from "../../../api-config/Headers"

const initialState = {
    saveLoading: false,
    savePrivilages: [],
    error: ''
}

const storePrivilege = createAsyncThunk("api/store/role_privilges", async (formData) => {
    return await authConfig.post("/store/role_privilges", formData, { headers: Headers() }).then((response) => {
        return response.data
    }).catch((e) => {
        console.log("error", e)
    })
})

const storePrivilegeSlice = createSlice({
    name: "storePrivilegeSlice",
    initialState,
    extraReducers: (builder) => {
        builder.
            addCase(storePrivilege.pending, (state) => {
                state.saveLoading = true
            })
            .addCase(storePrivilege.fulfilled, (state, { payload }) => {
                state.savePrivilages = payload
                state.saveLoading = false
            })
            .addCase(storePrivilege.rejected, (state) => {
                state.error = "Something went wrong"
            })
    }
})

export { storePrivilege }
export default storePrivilegeSlice.reducer