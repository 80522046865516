import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { authConfig } from "../../../api-config/authConfig"

const initialState = {
    moduleLoading :false,
    superadminModules:[],
    error:''
}

const getSuperAdminModules = createAsyncThunk("api/getSuperAdminModules", async() => {
    return await authConfig.get("/get_list_modules_by_admin").then((response) => {
        return response.data
    }).catch((e) => {
        console.log("error", e)
    })
})

const SuperAdminModuleSlice = createSlice({
    name:"SuperAdminModuleSlice",
    initialState,
    extraReducers : (builder) => {
        builder.
        addCase(getSuperAdminModules.pending, (state) => {
            state.moduleLoading = true
        })
        .addCase(getSuperAdminModules.fulfilled, (state, {payload}) => {
            state.superadminModules = payload
            state.moduleLoading = false
        })
        .addCase(getSuperAdminModules.rejected, (state) => {
            state.error = "Something went wrong"
        })
    }
})

export {getSuperAdminModules}
export default SuperAdminModuleSlice.reducer