import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { authConfig } from "../../../api-config/authConfig"

const initialState = {
    saveLoading :false,
    saveSuperAdminPrivilages:[],
    error:''
}

const storeSuperAdminPrivilege = createAsyncThunk("api/store/storeSuperAdminPrivilege", async(formData) => {
    return await authConfig.post("/store_super_admin/roles_privilges", formData).then((response) => {
        return response.data
    }).catch((e) => {
        console.log("error", e)
    })
})

const SaveSuperAdminPrivilegeSlice = createSlice({
    name:"SaveSuperAdminPrivilegeSlice",
    initialState,
    extraReducers : (builder) => {
        builder.
        addCase(storeSuperAdminPrivilege.pending, (state) => {
            state.saveLoading = true
        })
        .addCase(storeSuperAdminPrivilege.fulfilled, (state, {payload}) => {
            state.saveSuperAdminPrivilages = payload
            state.saveLoading = false
        })
        .addCase(storeSuperAdminPrivilege.rejected, (state) => {
            state.error = "Something went wrong"
        })
    }
})

export {storeSuperAdminPrivilege}
export default SaveSuperAdminPrivilegeSlice.reducer