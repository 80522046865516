import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { authConfig } from "../../../api-config/authConfig"


const initialState = {
    loading : false,
    SuperAdminRoles:[],
    error:''
}

const getSuperAdminRoles = createAsyncThunk("api/getSuperAdminRoles", async() => {
    return await authConfig.get("/get_list_roles_by_admin").then((response) => {
    return response.data
    }).catch((e) => {
        console.log("error", e)
    })
})

const SuperAdminRoleSlice = createSlice({
    name:"SuperAdminRoleSlice",
    initialState,
    extraReducers: (builder) => {
        builder.
        addCase(getSuperAdminRoles.pending, (state) => {
            state.loading = true
        })
        .addCase(getSuperAdminRoles.fulfilled, (state, {payload}) => {
            state.SuperAdminRoles = payload
            state.loading = false
        })
        .addCase(getSuperAdminRoles.rejected, (state) => {
            state.error = "Something went wrong"
            state.loading = false
        })
    }
})
export {getSuperAdminRoles}
export default SuperAdminRoleSlice.reducer