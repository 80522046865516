import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { authConfig } from "../../../api-config/authConfig"
import Headers from "../../../api-config/Headers"

const initialState = {
    createLoading : false,
    createResponse : [],
    error : ""
}

const createSchoolStaffs = createAsyncThunk("api/createSchoolStaff", async(formData) => {
    return await authConfig.post("/user_staff_store_by_school", formData, {headers: Headers()}).then((response) => {
        return response.data
    }).catch((e) => {
        console.log(e.errors, "Error")
    })
})

const CreateSchoolStaffSlice = createSlice({
    name : "CreateSchoolStaffSlice",
    initialState,
    extraReducers: (builder) => {
        builder.
        addCase(createSchoolStaffs.pending, (state) => {
            state.createLoading = true
        })
        .addCase(createSchoolStaffs.fulfilled, (state, {payload}) => {
            state.createResponse = payload
            state.createLoading = false
        })
        .addCase(createSchoolStaffs.rejected, (state, {error}) => {
            state.createLoading = false
            state.error = "Something went wrong!"
        })
    }
})

export {createSchoolStaffs}

export default CreateSchoolStaffSlice.reducer