import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { authConfig } from "../../../api-config/authConfig"
import Headers from "../../../api-config/Headers"

const initialState = {
    loadingDesignation: false,
    schoolStaffRoles: [],
    error: ''
}

const getSchoolStaffRoles = createAsyncThunk("api/getDesignations", async (formData) => {
    return await authConfig.post("/get_list_roles", formData, { headers: Headers() }).then((response) => {
        return response.data
    }).catch((e) => {
        console.log(e.error)
    })
})

const SchoolStaffRolesSlice = createSlice({
    name: "SchoolStaffRolesSlice",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getSchoolStaffRoles.pending, (state) => {
                state.loadingDesignation = true
                state.error = ''
            })
            .addCase(getSchoolStaffRoles.fulfilled, (state, action) => {
                state.loadingDesignation = false
                state.schoolStaffRoles = action.payload
            })
            .addCase(getSchoolStaffRoles.rejected, (state, action) => {
                state.loadingDesignation = false
                state.error = action.error.message
            })
    }
})
export { getSchoolStaffRoles }
export default SchoolStaffRolesSlice.reducer