import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { authConfig, noAuthConfig } from "../../../api-config/authConfig"
import Headers from "../../../api-config/Headers"

const initialState = {
    loadingGender : false,
    loadingCountry : false,
    gender: [],
    country: [],
    error : ''
}

const getGender = createAsyncThunk("api/getGender", async() => {
    return await authConfig.get("/list_gender", {headers: Headers()}).then((response) => {
        return response.data
    }).catch((e) => {
        console.log(e.error)
    })
})

const getCountry = createAsyncThunk("api/getCountry", async() => {
    return await noAuthConfig.get("/get_countries", {
        headers: Headers()
    }).then((response) => {
        return response.data
    }).catch((e) => {
        console.log(e.error)
    })
})

const GenderandCountrySlice = createSlice({
    name :"GenderandCountrySlice",
    initialState,
    extraReducers : (builder) => {
        builder
        .addCase(getGender.pending, (state) => {
            state.loadingGender = true
            state.error = ''
        })
        .addCase(getGender.fulfilled, (state, action) => {
            state.loadingGender = false
            state.gender = action.payload
        })
        .addCase(getGender.rejected, (state, action) => {
            state.loadingGender = false
            state.error = action.error.message
        })
        .addCase(getCountry.pending, (state) => {
            state.loadingCountry = true
            state.error = ''
        })
        .addCase(getCountry.fulfilled, (state, action) => {
            state.loadingCountry = false
            state.country = action.payload
        })
        .addCase(getCountry.rejected, (state, action) => {
            state.loadingCountry = false
            state.error = action.error.message
        })
    }
})
export {getGender, getCountry}
export default GenderandCountrySlice.reducer