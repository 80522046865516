import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { authConfig } from "../../../api-config/authConfig"

const initialState = {
 loadRemoveResponse : false,
 removeResponse : [],
 error : ""
}

const removeSuperAdminRoles = createAsyncThunk("api/removeSuperAdminRoles", async(formData) => {
    return await authConfig.post(`/destroy_role_admin/${formData.id}`).then((response) => {
        return response.data
    }).catch((e) => {
        console.log(e.error)
    })
})

const RemoveSuperAdminRolesSlice = createSlice({
    name : "RemoveSuperAdminRolesSlice",
    initialState,
    extraReducers : (builder) => {
        builder.
        addCase(removeSuperAdminRoles.pending, (state) => {
            state.loadRemoveResponse = true
        }).
        addCase(removeSuperAdminRoles.fulfilled, (state, {payload}) => {
            state.removeResponse = payload
            state.loadRemoveResponse = false
        }).
        addCase(removeSuperAdminRoles.rejected, (state) => {
            state.error = "Something went wrong!"
        })
    }
})

export {removeSuperAdminRoles}
export default RemoveSuperAdminRolesSlice.reducer