import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { authConfig } from "../../../api-config/authConfig"

const initialState = {
 loadSuperAdminRoles : false,
 superAdminRoles : [],
 error : ""
}

const getSuperAdminRoles = createAsyncThunk("api/getSuperAdminRoles", async() => {
    return await authConfig.get("/list_all_roles_super_admin").then((response) => {
        return response.data
    }).catch((e) => {
        console.log(e.error)
    })
})

const GetSuperAdminRolesSlice = createSlice({
    name : "GetSuperAdminRolesSlice",
    initialState,
    extraReducers : (builder) => {
        builder.
        addCase(getSuperAdminRoles.pending, (state) => {
            state.loadSuperAdminRoles = true
        }).
        addCase(getSuperAdminRoles.fulfilled, (state, {payload}) => {
            state.superAdminRoles = payload
            state.loadSuperAdminRoles = false
        }).
        addCase(getSuperAdminRoles.rejected, (state) => {
            state.error = "Something went wrong!"
        })
    }
})

export {getSuperAdminRoles}
export default GetSuperAdminRolesSlice.reducer