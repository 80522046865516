import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { authConfig } from "../../../api-config/authConfig"

const initialState = {
    loadingDesignation : false,
    designation: [],
    error : ''
}

const getDesignations = createAsyncThunk("api/getDesignations", async() => {
    return await authConfig.get("/get_list_roles_by_admin").then((response) => {
        return response.data
    }).catch((e) => {
        console.log(e.error)
    })
})

const StaffDesignationSlice = createSlice({
    name :"StaffDesignationSlice",
    initialState,
    extraReducers : (builder) => {
        builder
        .addCase(getDesignations.pending, (state) => {
            state.loadingDesignation = true
            state.error = ''
        })
        .addCase(getDesignations.fulfilled, (state, action) => {
            state.loadingDesignation = false
            state.designation = action.payload
        })
        .addCase(getDesignations.rejected, (state, action) => {
            state.loadingDesignation = false
            state.error = action.error.message
        })
    }
})
export {getDesignations}
export default StaffDesignationSlice.reducer