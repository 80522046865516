import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { authConfig } from "../../../api-config/authConfig"

const initialState = {
 loadStatusChangeResponse : false,
 statusChangeResponse : [],
 error : ""
}

const statusChangeSuperAdminRoles = createAsyncThunk("api/statusChangeSuperAdminRoles", async(formData) => {
    return await authConfig.post("/Status_change_super_admin_role", formData).then((response) => {
        return response.data
    }).catch((e) => {
        console.log(e.error)
    })
})

const StatusChangeSuperAdminRoleSlice = createSlice({
    name : "StatusChangeSuperAdminRoleSlice",
    initialState,
    extraReducers : (builder) => {
        builder.
        addCase(statusChangeSuperAdminRoles.pending, (state) => {
            state.loadStatusChangeResponse = true
        }).
        addCase(statusChangeSuperAdminRoles.fulfilled, (state, {payload}) => {
            state.statusChangeResponse = payload
            state.loadStatusChangeResponse = false
        }).
        addCase(statusChangeSuperAdminRoles.rejected, (state) => {
            state.error = "Something went wrong!"
        })
    }
})

export {statusChangeSuperAdminRoles}
export default StatusChangeSuperAdminRoleSlice.reducer