import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { authConfig } from "../../../api-config/authConfig"
import Headers from "../../../api-config/Headers"

const initialState = {
    loading: false,
    roles: [],
    error: ''
}

const getRoles = createAsyncThunk("api/getAllRoles", async (school_idd) => {
    const request = {
        school_id: school_idd
    }
    return await authConfig.post("/get_list_roles", request, {
        headers: Headers()
    }).then((response) => {
        return response.data
    }).catch((e) => {
        console.log("error", e)
    })
})

const PermissionSlice = createSlice({
    name: "PermissionSlice",
    initialState,
    extraReducers: (builder) => {
        builder.
            addCase(getRoles.pending, (state) => {
                state.loading = true
            })
            .addCase(getRoles.fulfilled, (state, { payload }) => {
                state.roles = payload
                state.loading = false
            })
            .addCase(getRoles.rejected, (state) => {
                state.error = "Something went wrong"
                state.loading = false
            })
    }
})
export { getRoles }
export default PermissionSlice.reducer