import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { authConfig } from "../../../api-config/authConfig"

const initialState = {
    updateLoading : false,
    updateResponse : [],
    error : ""
}
// change_status_user_staff_admin/{id}
const updateSuperAdminStaff = createAsyncThunk("api/updateSuperAdminStaff", async(formData) => {
    return await authConfig.post("/change_status_user_staff_admin", formData).then((response) => {
        return response.data
    }).catch((e) => {
        console.log(e, "Error")
    })
})

const UpdateSuperAdminStaffSlice = createSlice({
    name : "UpdateSuperAdminStaffSlice",
    initialState,
    extraReducers: (builder) => {
        builder.
        addCase(updateSuperAdminStaff.pending, (state) => {
            state.updateLoading = true
        })
        .addCase(updateSuperAdminStaff.fulfilled, (state, {payload}) => {
            state.updateResponse = payload
            state.updateLoading = false
        })
        .addCase(updateSuperAdminStaff.rejected, (state) => {
            state.updateLoading = false
            state.error = "Something went wrong!"
        })
    }
})

export {updateSuperAdminStaff}

export default UpdateSuperAdminStaffSlice.reducer